import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwe encrypt`}</strong>{` -- encrypt a payload using JSON Web Encryption (JWE)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwe encrypt
[--alg=<key-enc-algorithm>] [--enc=<content-enc-algorithm>]
[--key=<file>] [--jwks=<jwks>] [--kid=<kid>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwe encrypt`}</strong>{` encrypts a payload using JSON Web Encryption
(JWE). By default, the payload to encrypt is read from STDIN and the JWE data
structure will be written to STDOUT.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto jwe`}</strong>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`key-enc-algorithm`}</inlineCode>{`, `}<strong parentName="p">{`--algorithm`}</strong>{`=`}<inlineCode parentName="p">{`key-enc-algorithm`}</inlineCode>{`
The cryptographic algorithm used to encrypt or determine the value of the
content encryption key (CEK). Algorithms are case-sensitive strings defined in
RFC7518. The selected algorithm must be compatible with the key type. This
flag is optional. If not specified, the `}<strong parentName="p">{`"alg"`}</strong>{` member of the JWK is used. If
the JWK has no `}<strong parentName="p">{`"alg"`}</strong>{` member then a default is selected depending on the JWK
key type. If the JWK has an `}<strong parentName="p">{`"alg"`}</strong>{` member and the `}<strong parentName="p">{`--alg`}</strong>{` flag is passed the two
options must match unless the `}<strong parentName="p">{`--subtle`}</strong>{` flag is also passed.`}</p>
    <p><inlineCode parentName="p">{`key-enc-algorithm`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA1_5`}</strong>{`: RSAES-PKCS1-v1_5`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA-OAEP`}</strong>{`: RSAES OAEP using default parameters`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA-OAEP-256`}</strong>{` (default for RSA keys): RSAES OAEP using SHA-256 and MGF1 with SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A128KW`}</strong>{`: AES Key Wrap with default initial value using 128-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A192KW`}</strong>{`: AES Key Wrap with default initial value using 192-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A256KW`}</strong>{`: AES Key Wrap with default initial value using 256-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`dir`}</strong>{`: Direct use of a shared symmetric key as the content encryption key (CEK)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES`}</strong>{` (default for EC keys): Elliptic Curve Diffie-Hellman Ephemeral Static key agreement`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES+A128KW`}</strong>{`: ECDH-ES using Concat KDF and CEK wrapped with "A128KW`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES+A192KW`}</strong>{`: ECDH-ES using Concat KDF and CEK wrapped with "A192KW`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES+A256KW`}</strong>{`: ECDH-ES using Concat KDF and CEK wrapped with "A256KW`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A128GCMKW`}</strong>{`: Key wrapping with AES GCM using 128-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A192GCMKW`}</strong>{`: Key wrapping with AES GCM using 192-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A256GCMKW`}</strong>{` (default for oct keys): Key wrapping with AES GCM using 256-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PBES2-HS256+A128KW`}</strong>{`: PBES2 with HMAC SHA-256 and "A128KW" wrapping`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PBES2-HS384+A192KW`}</strong>{`: PBES2 with HMAC SHA-256 and "A192KW" wrapping`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PBES2-HS512+A256KW`}</strong>{`: PBES2 with HMAC SHA-256 and "A256KW" wrapping`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--enc`}</strong>{`=`}<inlineCode parentName="p">{`content-enc-algorithm`}</inlineCode>{`, `}<strong parentName="p">{`--encryption-algorithm`}</strong>{`=`}<inlineCode parentName="p">{`content-enc-algorithm`}</inlineCode>{`
The cryptographic content encryption algorithm used to perform authenticated
encryption on the plaintext payload (the content) to produce ciphertext and
the authentication tag.`}</p>
    <p><inlineCode parentName="p">{`content-enc-algorithm`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A128CBC-HS256`}</strong>{`: AES_128_CBC_HMAC_SHA_256 authenticated encryption algorithm`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A192CBC-HS384`}</strong>{`: AES_192_CBC_HMAC_SHA_384 authenticated encryption algorithm`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A256CBC-HS512`}</strong>{`: AES_256_CBC_HMAC_SHA_512 authenticated encryption algorithm`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A128GCM`}</strong>{`: AES GCM using 128-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A192GCM`}</strong>{`: AES GCM using 192-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A256GCM`}</strong>{` (default): AES GCM using 256-bit key`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the JWE recipient's public key.
JWEs can be encrypted for a recipient using a public JWK or a PEM encoded public key.`}</p>
    <p><strong parentName="p">{`--jwks`}</strong>{`=`}<inlineCode parentName="p">{`jwks`}</inlineCode>{`
The JWK Set containing the recipient's public key. The `}<inlineCode parentName="p">{`jwks`}</inlineCode>{` argument should
be the name of a file. The file contents should be a JWK Set. The `}<strong parentName="p">{`--jwks`}</strong>{`
flag requires the use of the `}<strong parentName="p">{`--kid`}</strong>{` flag to specify which key to use.`}</p>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The ID of the recipient's public key. `}<inlineCode parentName="p">{`kid`}</inlineCode>{` is a case-sensitive string. When
used with `}<strong parentName="p">{`--key`}</strong>{` the `}<inlineCode parentName="p">{`kid`}</inlineCode>{` value must match the `}<strong parentName="p">{`"kid"`}</strong>{` member of the JWK. When
used with `}<strong parentName="p">{`--jwks`}</strong>{` (a JWK Set) the `}<inlineCode parentName="p">{`kid`}</inlineCode>{` value must match the `}<strong parentName="p">{`"kid"`}</strong>{` member of
one of the JWKs in the JWK Set.`}</p>
    <p><strong parentName="p">{`--typ`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--type`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The media type of the JWE, used for disambiguation in applications where
more than one type of JWE may be processed. While this parameter might be
useful to applications, it is ignored by JWE implementations.`}</p>
    <p><strong parentName="p">{`--cty`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`--content-type`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The media type of the JWE payload, used for disambiguation of JWE objects in
applications where more than one JWE payload type may be present. This
parameter is ignored by JWE implementations, but may be processed by
applications that use JWE.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      